<template>
  <ChargerBody msg="Welcome to Your Vue.js App"/>
</template>

<script>
import ChargerBody from "@/components/ChargerBody";

export default {
  name: 'App',
  components: {
    ChargerBody
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
@font-face {
  font-family: 'LED BOARD';
  font-style: normal;
  font-weight: normal;
  src: local('LED BOARD'), url(./fonts/LEDBOARD.woff) format('woff');
}
@font-face {
  font-family: 'LED BOARD REVERSED';
  font-style: normal;
  font-weight: normal;
  src: local('LED BOARD REVERSED'), url(./fonts/LEDBDREV.woff) format('woff');
}

@font-face {
  font-family: 'DotMatrix Regular';
  font-style: normal;
  font-weight: normal;
  src: local('DotMatrix Regular'), url(./fonts/DOTMATRX.woff) format('woff');
}


@font-face {
  font-family: 'DotMatrix_TR Regular';
  font-style: normal;
  font-weight: normal;
  src: local('DotMatrix_TR Regular'), url(./fonts/DotMatrix_TR.woff) format('woff');
}
</style>

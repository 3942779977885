<template>
<!--  TODO:-->
  <!-- modify and save values when at step 3,4 -- made it for lipo - OK for release 1-->
  <!-- set min/max per batt/progr type -- made it for lipo - OK for release 1-->
  <!-- long press on btns 2/3 when modifing adding rapidly - not necesary for release 1-->
<!--  add download guide-->
  <div class="main">
    <div class="title">
      <h1>Acest program inca nu este complet functional</h1>
      <h1>Aceasta pagina nu este oprimizata pentru mobil</h1>
    </div>
    <div id="charger">
      <div id="chargerScreen">
        <div id="chargerScreenTitle">
          <div v-for="item in 16" :key="item" class="screenEl">{{screen.top[item - 1]}}</div>
        </div>
        <div id="chargeScreenDetails">
          <div v-for="item in screen.leftSide" :key="item" class="screenEl"><div :class="(blinkLeft && screen.blinkLeft.includes(item - 1))?'blinkerClass':''">{{retunText('left')[item - 1]}}</div></div>
          <div v-for="item in (16 - screen.leftSide)" :key="item" class="screenEl"><div :class="(blinkRight && screen.blinkRight.includes(item - 1))?'blinkerClass':''">{{retunText('right')[item - 1]}}</div></div>
        </div>
      </div>
      <img src="../assets/charger.png" alt="charger">
      <div class="buttons">
        <button class="chargerBtn" @click="buttonActions(1)"></button>
        <button class="chargerBtn" @click="buttonActions(2)"></button>
        <button class="chargerBtn" @click="buttonActions(3)"></button>
        <button class="chargerBtn" @mousedown="testStart" @mouseup="testEnd"></button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ChargerBody',
  data(){
    return {
      longClickTimer: 0,
      longClickInterval: null,
      chargingScreen: 0,
      interval: null,
      isBattCheck: false,
      isCharging: false,
      isInitialLoad: true,
      blinkLeft: false,
      blinkRight: false,
      previousBlinker: '',
      step: 1,
      oneCell: 3.7,
      previousBattType: 0,
      selectedItems: {
        selStep1: 0,
        selStep2: 0
      },
      screen: {id: 0, top:'', left: '', addLeft:'', right:'', addRight: '', leftSide: 0, blinkLeft: [], blinkRight: [], limitsLeft: [], limitsRight: [] },
      step1: [
        {id: 0, top:'PROGRAM SELECT', left: '', addLeft: '', right:'LiPo BATT', addRight: '', leftSide: 7, blinkLeft: [], blinkRight: [], limitsLeft: [], limitsRight: [] },
        {id: 1, top:'PROGRAM SELECT', left: '', addLeft: '', right:'NiMH BATT', addRight: '', leftSide: 7, blinkLeft: [], blinkRight: [], limitsLeft: [], limitsRight: [] },
        {id: 2, top:'PROGRAM SELECT', left: '', addLeft: '', right:'NiCd BATT', addRight: '', leftSide: 7, blinkLeft: [], blinkRight: [], limitsLeft: [], limitsRight: [] },
        {id: 3, top:'PROGRAM SELECT', left: '', addLeft: '', right:'Pb BATT', addRight: '', leftSide: 7, blinkLeft: [], blinkRight: [], limitsLeft: [], limitsRight: [] },
        {id: 4, top:'PROGRAM SELECT', left: '', addLeft: '', right:'SAVE DATA', addRight: '', leftSide: 7, blinkLeft: [], blinkRight: [], limitsLeft: [], limitsRight: [] },
        {id: 5, top:'PROGRAM SELECT', left: '', addLeft: '', right:'LOAD DATA', addRight: '', leftSide: 7, blinkLeft: [], blinkRight: [], limitsLeft: [], limitsRight: [] },
        {id: 6, top:'USER SET', left: '', addLeft: '', right:'PROGRAM->', addRight: '', leftSide: 7, blinkLeft: [], blinkRight: [], limitsLeft: [], limitsRight: [] }
      ],
      step2: [
        [
          {id: 0, top:'LiPo CHARGE', left: 2.2, addLeft: 'A', right:11.1, addRight: 'V(3S)', leftSide: 7, blinkLeft: [0,1,2], blinkRight: [0,1,2,3], limitsLeft: [0.1,5.0], limitsRight: [3.7,22.2] },
          {id: 1, top:'LiPo BALANCE', left: 1.1, addLeft: 'A', right:11.1, addRight: 'V(3S)', leftSide: 7, blinkLeft: [0,1,2], blinkRight: [0,1,2,3], limitsLeft: [0.1,5.0], limitsRight: [3.7,22.2] },
          {id: 2, top:'LiPo FAST CHG', left: 1.2, addLeft: 'A', right:11.1, addRight: 'V(3S)', leftSide: 7, blinkLeft: [0,1,2], blinkRight: [0,1,2,3], limitsLeft: [0.1,5.0], limitsRight: [3.7,22.2] },
          {id: 3, top:'LiPo STORAGE', left: 0.3, addLeft: 'A', right:11.1, addRight: 'V(3S)', leftSide: 7, blinkLeft: [0,1,2], blinkRight: [0,1,2,3], limitsLeft: [0.1,1.0], limitsRight: [3.7,22.2] },
          {id: 4, top:'LiPo DISCHARGE', left: 0.4, addLeft: 'A', right:11.1, addRight: 'V(3S)', leftSide: 7, blinkLeft: [0,1,2], blinkRight: [0,1,2,3], limitsLeft: [0.1,1.0], limitsRight: [3.7,22.2] },
        ],
        [
          {id: 0, top:'NiMH CHARGE  Man', left: '', addLeft: 'CURRENT', right:0.1, addRight: 'A', leftSide: 7, blinkLeft: [], blinkRight: [5,6,7], limitsLeft: [], limitsRight: [] },
          {id: 1, top:'NiMH DISCHARGE', left: 0.1, addLeft: 'A', right:0.1, addRight: 'V', leftSide: 7, blinkLeft: [0,1,2], blinkRight: [5,6,7], limitsLeft: [], limitsRight: [] },
          {id: 2, top:'NiMH CYCLE', left: '', addLeft: 'DCHG>CHG', right:1, addRight: '', leftSide: 8, blinkLeft: [0,1,2,3,4,5,6,7], blinkRight: [7], limitsLeft: [], limitsRight: [] },
        ],
        [
          {id: 0, top:'NiCd CHARGE  Man', left: '', addLeft: 'CURRENT', right:0.1, addRight: 'A', leftSide: 7, blinkLeft: [], blinkRight: [5,6,7], limitsLeft: [], limitsRight: [] },
          {id: 1, top:'NiCd DISCHARGE', left: 0.1, addLeft: 'A', right:0.1, addRight: 'V', leftSide: 7, blinkLeft: [0,1,2], blinkRight: [5,6,7], limitsLeft: [], limitsRight: [] },
          {id: 2, top:'NiCd CYCLE', left: '', addLeft: 'DCHG>CHG', right:1, addRight: '', leftSide: 8, blinkLeft: [0,1,2,3,4,5,6,7], blinkRight: [7], limitsLeft: [], limitsRight: [] },
        ],
        [
          {id: 0, top:'Pb CHARGE', left: 0.1, addLeft: 'A', right:2.0, addRight: 'V(1P)', leftSide: 7, blinkLeft: [0,1,2], blinkRight: [0,1,2,3], limitsLeft: [], limitsRight: [] },
          {id: 1, top:'Pb DISCHARGE', left: 0.1, addLeft: 'A', right:2, addRight: 'V(1P)', leftSide: 7, blinkLeft: [0,1,2], blinkRight: [0,1,2,3], limitsLeft: [], limitsRight: [] },
        ],
        [
          {id: 0, top:'SAVE [01]   LiPo', left: 3.7, addLeft: 'A', right:5000, addRight: 'mAh', leftSide: 7, blinkLeft: [], blinkRight: [], limitsLeft: [], limitsRight: [] },
        ],
        [
          {id: 0, top:'LOAD [01]   LiPo', left: 3.7, addLeft: 'A', right:5000, addRight: 'mAh', leftSide: 7, blinkLeft: [], blinkRight: [], limitsLeft: [], limitsRight: [] },
        ],
        [
          {id: 0, top:'LiPo', left: '', addLeft: 'V.Type', right:3.7, addRight: 'V', leftSide: 7, blinkLeft: [], blinkRight: [5,6,7], limitsLeft: [], limitsRight: [] },
          {id: 1, top:'LiPo/LiIo/LiFe', left: '', addLeft: 'CHK Time', right:10, addRight: 'min', leftSide: 8, blinkLeft: [], blinkRight: [3,4], limitsLeft: [], limitsRight: [] },
          {id: 2, top:'NiMH Sensitivity', left: '', addLeft: 'D.Peak', right:'', addRight: 'Default', leftSide: 7, blinkLeft: [], blinkRight: [1,2,3,4,5,6,7,8], limitsLeft: [], limitsRight: [] },
          {id: 3, top:'NiCd Sensitivity', left: '', addLeft: 'D.Peak', right:'', addRight: 'Default', leftSide: 7, blinkLeft: [], blinkRight: [1,2,3,4,5,6,7,8], limitsLeft: [], limitsRight: [] },
          {id: 4, top:'USB/Temp Select', left: '', addLeft: 'Temp Cut-Off', right:80, addRight: 'C', leftSide: 12, blinkLeft: [0,1,2,3,4,5,6,7,8,9,10,11], blinkRight: [1,2], limitsLeft: [], limitsRight: [] },
          {id: 5, top:'Waste Time', left: '', addLeft: 'CHG>DCHG', right:1, addRight: 'min', leftSide: 8, blinkLeft: [], blinkRight: [3,4], limitsLeft: [], limitsRight: [] },
          {id: 6, top:'Safety Timer', left: '', addLeft: 'ON', right:120, addRight: 'min', leftSide: 7, blinkLeft: [0,1,2], blinkRight: [3,4,5], limitsLeft: [], limitsRight: [] },
          {id: 7, top:'Capacity Cut-Off', left: '', addLeft: 'ON', right:5000, addRight: 'mAh', leftSide: 7, blinkLeft: [0,1,2], blinkRight: [1,2,3,4,5], limitsLeft: [], limitsRight: [] },
          {id: 8, top:'Key Beep      ON', left: '', addLeft: 'Buzzer', right:'', addRight: 'ON', leftSide: 7, blinkLeft: [], blinkRight: [], limitsLeft: [], limitsRight: [] },
          {id: 9, top:'Input Power Low', left: '', addLeft: 'Cut-Off', right:10.0, addRight: 'V', leftSide: 7, blinkLeft: [], blinkRight: [4,5,6,7], limitsLeft: [], limitsRight: [] },
        ]
      ],
      step5: [
        {id: 0, top:'Battery Check', left: '', addLeft:'wait...', right:'', addRight: '', leftSide: 8, blinkLeft: [], blinkRight: [], limitsLeft: [], limitsRight: [] },
        {id: 1, top:'', left: '', addLeft:'CONFIRM(ENTER)', right:'', addRight: '', leftSide: 15, blinkLeft: [], blinkRight: [], limitsLeft: [], limitsRight: [] },
        {id: 3, top:'', left: '', addLeft:'CANCEL(STOP)', right:'', addRight: '', leftSide: 15, blinkLeft: [], blinkRight: [], limitsLeft: [], limitsRight: [] },
      ],
      step6: [
        {id: 0, top:'Li3S 0.1A 11.55V', left: '', addLeft:'CHG 000:55', right: '00325', addRight: '', leftSide: 10, blinkLeft: [], blinkRight: [], limitsLeft: [], limitsRight: [] },
        {id: 1, top:'3.87 3.84 3.94', left: '0.00 0.00 0.00', addLeft:'', right:'', addRight: '', leftSide: 15, blinkLeft: [], blinkRight: [], limitsLeft: [], limitsRight: [] },
      ]
    }
  },
  methods: {
    testStart(){
      this.longClickInterval = setInterval(() => {this.longClickTimer++}, 100)
    },
    testEnd(){
      clearInterval(this.longClickInterval);

      if(this.longClickTimer >= 20 && this.step > 1){
        this.startCharge();
      } else {
        this.buttonActions(4);
      }

      this.longClickTimer = 0;
    },
    startCharge(){
      if (this.step < 2) return;
      if (this.isBattCheck) return;
      if (this.isCharging) return;

      this.isBattCheck = true;
      let noOfCells = (this.screen.right / this.oneCell).toFixed();
      let topText = "R: " + noOfCells + "SER S: " + noOfCells + "SER";
      this.step5[1].top = topText;
      this.step5[2].top = topText;

      this.step = 5;
      this.screen = this.step5[0];

      let checkBattStep = 1;

      this.interval = setInterval(() => {
        this.screen = this.step5[checkBattStep];
        checkBattStep++;
        if (checkBattStep > 2) checkBattStep = 1;
      }, 1000);

      setTimeout(() => {
        if(!this.isCharging) this.stopBattCheck(true);
      }, 8000);
    },
    stopBattCheck(callSetScreen){
      clearInterval(this.interval);
      this.step = 2;
      this.isBattCheck = false;
      this.isCharging = false;
      if(callSetScreen) this.setScreen();
    },
    retunText(side){
      let string = '';
      if (side === 'left') {
        let item = this.screen.left.toString();
        if (item.length === 1) item = item.concat('.0')
        string = item.concat(this.screen.addLeft)
      }
      if (side === 'right') {
        string = this.screen.right.toString().concat(this.screen.addRight);
        let length = 16 - this.screen.leftSide - string.length
        string = ' '.repeat(length).concat(string);
      }
      return string
    },
    setScreen(){
      if(this.isInitialLoad){
        this.screen = this.step1[this.selectedItems.selStep1];
        this.isInitialLoad = false;
        return
      }
      this.blinkLeft = false;
      this.blinkRight = false;
      switch (this.step){
        case 1:
          this.screen = this.step1[this.selectedItems.selStep1];
          break;
        case 2:
          this.screen = this.step2[this.selectedItems.selStep1][this.selectedItems.selStep2];
          break;
        case 3:
          if(this.screen.blinkLeft.length !== 0) {
            this.blinkLeft = true;
            this.previousBlinker = 'left';
            this.screen = this.step2[this.selectedItems.selStep1][this.selectedItems.selStep2];
            break;
          }
          if (this.screen.blinkRight.length !== 0){
            this.blinkRight = true;
            this.previousBlinker = 'right';
            this.screen = this.step2[this.selectedItems.selStep1][this.selectedItems.selStep2];
            break;
          }
          break;
        case 4:
          if ((this.screen.blinkRight.length !== 0 && this.previousBlinker === 'left') || (this.screen.blinkRight.length !== 0 && this.previousBlinker === 'right') ){
            this.blinkRight = true;
            this.previousBlinker = 'right';
            this.screen = this.step2[this.selectedItems.selStep1][this.selectedItems.selStep2];
          } else {
            this.previousBlinker = 'left';
          }
          break;
        case 6:
          this.screen = this.step6[this.chargingScreen];
          break;
        default: break;
      }
    },
    checkIfResetNeeded(){
      switch(this.step){
        case 1:
          if(this.screen.id === this.previousBattType) return
          break;
        default: break;
      }
      this.selectedItems.selStep2 = 0;
    },
    incrementBattType(isDecrese){
      let currentBattType = this.selectedItems.selStep1;
      if (isDecrese === true){
        currentBattType -= 1
      } else {
        currentBattType += 1
      }
      if (currentBattType < 0) return this.step1.length - 1;
      if (currentBattType === this.step1.length) return 0;
      return currentBattType;
    },
    button1Action(){
      switch (this.step) {
        case 1:
          this.selectedItems.selStep1 = this.incrementBattType(false);
          break;
        case 5:
          this.stopBattCheck(false);
          break;
        case 6:
          this.stopBattCheck(false);
          break;
        default:
          this.step = 1;
          break;
      }
    },
    button2Action(){
      switch (this.step) {
        case 1:
          this.selectedItems.selStep1 = this.incrementBattType(true);
          break;
        case 2:
          this.selectedItems.selStep2--;
          if (this.selectedItems.selStep2 < 0) this.selectedItems.selStep2 = this.step2[this.selectedItems.selStep1].length - 1;
          break;
        case 3:
          if(this.blinkLeft && this.selectedItems.selStep1 === 0){
            let item = this.step2[this.selectedItems.selStep1][this.selectedItems.selStep2];
            let number = item.left;
            let limit = item.limitsLeft[0];
            const add = - 0.1;
            let result = parseFloat((number + add).toFixed(1));
            if(result < limit) {
              result = number
            }
            this.step2[this.selectedItems.selStep1][this.selectedItems.selStep2].left = parseFloat(result.toFixed(1));
          }
          break;
        case 4:
          if(this.blinkRight  && this.selectedItems.selStep1 === 0){
            let item = this.step2[this.selectedItems.selStep1][this.selectedItems.selStep2];
            let limit = item.limitsRight[0];
            let number = item.right;
            const add = - 3.7;
            let result = parseFloat((number + add).toFixed(1));
            if (result < limit) {
              result = parseFloat(number.toFixed(1))
            }
            this.step2[this.selectedItems.selStep1][this.selectedItems.selStep2].right = parseFloat(result.toFixed(1));
            let textNo = parseFloat(((result) / add * -1).toFixed(1));
            this.step2[this.selectedItems.selStep1][this.selectedItems.selStep2].addRight = 'V('.concat(textNo, 'S)');
          }
          break;
        default: break;
      }
    },
    button3Action(){
      switch (this.step) {
        case 1: break;
        case 2: this.selectedItems.selStep2++;
          if (this.selectedItems.selStep2 > this.step2[this.selectedItems.selStep1].length - 1) this.selectedItems.selStep2 = 0;
          break;
        case 3:
          if(this.blinkLeft  && this.selectedItems.selStep1 === 0){
            let item = this.step2[this.selectedItems.selStep1][this.selectedItems.selStep2];
            let number = item.left;
            let limit = item.limitsLeft[1];
            const add = 0.1;
            let result = parseFloat((number + add).toFixed(1));
            if(result > limit){
              result = number
            }
            this.step2[this.selectedItems.selStep1][this.selectedItems.selStep2].left = parseFloat(result.toFixed(1));
          }
          break;
        case 4:
          if(this.blinkRight  && this.selectedItems.selStep1 === 0){
            let item = this.step2[this.selectedItems.selStep1][this.selectedItems.selStep2];
            let limit = item.limitsRight[1];
            let number = item.right;
            const add = 3.7;
            let result = parseFloat((number + add).toFixed(1));
            if (result > limit) {
              result = parseFloat(number.toFixed(1))
            }
            this.step2[this.selectedItems.selStep1][this.selectedItems.selStep2].right = parseFloat(result.toFixed(1));
            let textNo = parseFloat(((result) / add).toFixed(1));
            this.step2[this.selectedItems.selStep1][this.selectedItems.selStep2].addRight = 'V('.concat(textNo, 'S)');

          }
          break;
        case 6:
          this.chargingScreen++;
          if(this.chargingScreen > 1) this.chargingScreen = 0;
          break;
        default: break;
      }
    },
    button4Action(){
      switch (this.step) {
        case 1:
          this.checkIfResetNeeded();
          this.previousBattType = this.selectedItems.selStep1;
          this.step++;
          break;
        case 2:
          if(this.screen.blinkLeft.length > 0 || this.screen.blinkRight.length > 0) this.step++;
          break;
        case 3:
          (this.blinkRight)?this.step--:this.step++;
          break;
        case 4:
          this.step = 2;
          break;
        case 5:
          this.step = 6;
          if (!this.isCharging) {
            clearInterval(this.interval);
            this.isCharging = true;
          }
          break;
        default: break;
      }
    },
    buttonActions(btnClicked){
      switch (btnClicked) {
        case 1:
          this.button1Action()
          break;
        case 2:
          this.button2Action()
          break;
        case 3:
          this.button3Action()
          break;
        case 4:
          this.button4Action()
          break;
        default: break;
      }
      this.setScreen();
    },
  },
  mounted() {
    this.setScreen()
  }
}
</script>

<style scoped>
#download{
   align-items: center;
   background-clip: padding-box;
   background-color: #fa6400;
   border: 1px solid transparent;
   border-radius: .25rem;
   box-shadow: rgba(0, 0, 0, 0.02) 0 1px 3px 0;
   box-sizing: border-box;
   color: #fff;
   cursor: pointer;
   display: inline-flex;
   font-family: system-ui,-apple-system,system-ui,"Helvetica Neue",Helvetica,Arial,sans-serif;
   font-size: 16px;
   font-weight: 600;
   justify-content: center;
   line-height: 1.25;
   margin: 0;
   min-height: 4rem;
   min-width: 7rem;
   padding: calc(.875rem - 1px) calc(1.5rem - 1px);
   position: relative;
   text-decoration: none;
   transition: all 250ms;
   user-select: none;
   -webkit-user-select: none;
   touch-action: manipulation;
   vertical-align: baseline;
   width: auto;
 }

#download:hover,
#download:focus {
  background-color: #fb8332;
  box-shadow: rgba(0, 0, 0, 0.1) 0 4px 12px;
}

#download:hover {
  transform: translateY(-1px);
}

#download:active {
  background-color: #c85000;
  box-shadow: rgba(0, 0, 0, .06) 0 2px 4px;
  transform: translateY(0);
}

.chargerBtn{
  width: 30px;
  border-radius: 50%;
  border: none;
  background: none;
  aspect-ratio: 1;
  cursor: pointer;
}
.buttons{
  display: flex;
  width: 246px;
  transform: translate(-14px,-95px);
  justify-content: space-between;
}
.screenEl{
  font-family: "DotMatrix Regular", Helvetica, Arial;
  width: calc((100% - 51px) / 16);
  height: 65%;
  background: rgb(0, 89, 179, 0.5);
  font-size: 1.6em;
  flex-shrink: 0;
  display: grid;
  place-items: center;

}
.blinkerClass {
  animation: blinker 1s linear infinite;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
.main{
  display: flex;
  flex-direction: column;
  align-items: center;
}
#charger{
  display: grid;
  place-items: center;
  position: relative;
  max-width: 700px;
}
#chargerScreen{
  position: absolute;
  top: 156px;
  left: 176px;
  width: 309px;
  height: 76px;
  display: flex;
  flex-direction: column;
  background: rgb(0, 230, 257);
  color: white;
  font-weight: bolder;
}
#chargeScreenDetails, #chargerScreenTitle{
  height: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 3px
}

</style>
